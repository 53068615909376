import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { HiddenInput } from "../atoms/HiddenInput";
import { TextField } from "../atoms/TextField";
import { XText } from "../atoms/XText";
import { SelectForm } from "../atoms/SelectForm";
import { CostFormsHeader } from "../molecules/CostFormsHeader";
import { CostBuildingRelatedCostForm } from "../molecules/CostBuildingRelatedCostForm";
import { CostOtherForm } from "../molecules/CostOtherForm";
import { NoNameTextField } from "../atoms/NoNameTextField";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { DialogSubContent } from "../molecules/DialogSubContent";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitCostBuildingRelatedCost, useInitCostBuildingRelatedCostOther, useInitCostLandRelatedCost, useInitCostLandRelatedCostLandAcquisitionCost, useInitCostLandRelatedCostOther, useInitCostSalesCost } from "../../utils/CustomHook";
import { onBlurCommonAction, removeChild, resetColumnForPropertyEdit, saveChildForPropertyEdit } from "../../utils/PropertyEdit";
import { UserContext } from "../../Context";

export const CostForms = React.memo(function costForms({
  property,
  costlandrelatedcost,
  costbuildingrelatedcost,
  costsalescost,
  businessbalance,
  initialized,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {

  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [isOpenLandRelatedForm, setIsOpenLandRelatedForm] = React.useState(false)
  const [isOpenBuildingsRelatedForm, setIsOpenBuildingsRelatedForm] = React.useState(false)
  const [isOpenSalesCostForm, setIsOpenSalesCostForm] = React.useState(false)

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    methods,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  const [choices, setChoices] = React.useState({});
  const [propertyId, setPropertyId] = React.useState(null)

  useInitCostLandRelatedCost(costlandrelatedcost, setValue, isFocused)

  const costLandRelatedCostLandAcquisitionCostFields = useInitCostLandRelatedCostLandAcquisitionCost(costlandrelatedcost.costlandrelatedcostlandacquisitioncost_set, setValue, isFocused, control, trigger)

  const [costLandRelatedCostOtherFields, costLandRelatedCostOtherAppend, costLandRelatedCostOtherRemove] = useInitCostLandRelatedCostOther(costlandrelatedcost.costlandrelatedcostother_set, setValue, isFocused, control, trigger)

  const [basicBuildingsRelatedFields, waterworksBureauPaymentFields] = useInitCostBuildingRelatedCost(costbuildingrelatedcost, setValue, isFocused, control, trigger)

  const [costBuildingRelatedCostOtherFields, costBuildingRelatedCostOtherAppend, costBuildingRelatedCostOtherRemove] = useInitCostBuildingRelatedCostOther(costbuildingrelatedcost.costbuildingrelatedcostother_set, setValue, isFocused, control, trigger)

  useInitCostSalesCost(costsalescost, setValue, isFocused)

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  React.useEffect(() => {
    setChoices(props.choices);
  });

  React.useEffect(() => {
    if (!(costlandrelatedcost && costbuildingrelatedcost && costsalescost && businessbalance)) {
      return;
    }

    initialized();
  }, [costlandrelatedcost, costbuildingrelatedcost, costsalescost, businessbalance]);

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const removeChildCommonParams = React.useMemo(() => [
    propertyId, getValues, setOriginPropertyData,
  ], [propertyId, getValues, setOriginPropertyData])

  const onBlurForCostBuildingRelatedCostOther = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "cost_building_related_cost", "costbuildingrelatedcost", "costbuildingrelatedcostother_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const createCostBuildingRelatedCostOtherForm = () => {
    costBuildingRelatedCostOtherAppend();
  };

  const handleRemoveForCostBuildingRelatedCostOther = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, costBuildingRelatedCostOtherRemove, index, "costbuildingrelatedcost", "costbuildingrelatedcostother_set")
  }, [propertyId]);

  const onBlurForCostLandRelatedCostOther = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "cost_land_related_cost", "costlandrelatedcost", "costlandrelatedcostother_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const createCostLandRelatedCostOtherForm = () => {
    costLandRelatedCostOtherAppend();
  };

  const handleRemoveForCostLandRelatedCostOther = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, costLandRelatedCostOtherRemove, index, "costlandrelatedcost", "costlandrelatedcostother_set")
  }, [propertyId]);

  const onBlurForCostLandRelatedCostLandAcquisitionCost = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "cost_land_related_cost", "costlandrelatedcost", "costlandrelatedcostlandacquisitioncost_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId]);

  const onBlurForCostLandRelatedCost = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "costlandrelatedcost",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const resetForCostLandRelatedCost = React.useCallback((name) => {
    const saveChildKeys = [
      "property", "costlandrelatedcost",
    ]
    resetColumnForPropertyEdit(name, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForCostSalesCost = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "costsalescost",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForCostBuildingRelatedCost = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "costbuildingrelatedcost",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const rules = React.useMemo(() => ({
    costlandrelatedcost: {
      costlandrelatedcostlandacquisitioncost_set: {
        land_acquisition_cost: {
          onBlur: onBlurForCostLandRelatedCostLandAcquisitionCost,
        },
      },
      costlandrelatedcostother_set: {
        cost: {
          onBlur: onBlurForCostLandRelatedCostOther
        },
        content: {
          onBlur: onBlurForCostLandRelatedCostOther
        },
      },
      fixed_asset_tax_burden_count : {
        onBlur: onBlurForCostLandRelatedCost,
      },
      fixed_asset_tax_burden_unit_price : {
        onBlur: onBlurForCostLandRelatedCost,
      },
      registration_charge_reward_advances_amount : {
        onBlur: onBlurForCostLandRelatedCost,
      },
      registration_charge_registration_licenses_tax : {
        onBlur: onBlurForCostLandRelatedCost,
      },
      current_state_measurement_subdivision_fee_count: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      stamp_tax_contract_when_revenue_stamp: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      purchase_intermediary_charge: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      current_state_meas_subdiv_chg_reward: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      current_state_meas_subdiv_chg_rev_stamp_fee: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      outsourcing_fee: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      landowner_approval_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      monthly_land_rent: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      moving_out_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      contract_non_conformity_liability_reserve_fund_count: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      building_body_demolition_work_amount: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      construction_residential_land_adjustment_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      position_specified_development_work_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      position_specified_development_work_pavement: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      position_specified_development_work_lead_in: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      power_pole_relocation_amount: { onBlur : onBlurForCostLandRelatedCost},
      power_pole_relocation_count: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      waterworks_and_gas_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      nagoya_design_hydraulic_pressure: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      block_fence_unit_price: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      block_fence_length: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      block_fence_amount : { onBlur: onBlurForCostLandRelatedCost, },
      neighborhood_control_amount : { onBlur: onBlurForCostLandRelatedCost, },
      neighborhood_control_unit_price : { onBlur: onBlurForCostLandRelatedCost, },
      neighborhood_control_count :{ onBlur: onBlurForCostLandRelatedCost, },

      retaining_wall_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      pavement_sb_off_lower_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      guardman_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      buried_cultural_property_exaction_cost: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      septic_tank_count: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      rainwater_penetration_trout_count: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      front_clean_water_main: {
        onBlur: onBlurForCostLandRelatedCost,
      },
      front_gas_plumbing: {
        onBlur: onBlurForCostLandRelatedCost,
      },
    },
    costbuildingrelatedcost: {
      basic_buildings_related_count: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      costbuildingrelatedcostother_set: {
        cost: {
          onBlur: onBlurForCostBuildingRelatedCostOther
        },
        content: {
          onBlur: onBlurForCostBuildingRelatedCostOther
        },
      },
      build_in_garage_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      site_extended_length: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      adding_exterior_unit_price: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      adding_exterior_count: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      waterworks_bureau_payment_count: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      soundproof_glass_count: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      body_building_work_unit_price: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      narrow_less_than_4m_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      hand_carrying_20m_under_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      lifting_transportation_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      porch_unit_price: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      porch_count: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      vertical_interval_process_basic_amount: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      vertical_interval_process_basic_unit_price: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      vertical_interval_processing_basic_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      floor_heating_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      bathroom_dryer_machine_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      fireproof_supported_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      outsourcing_design_tsubo: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      u_shaped_gutter_grating_amount: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      u_shaped_gutter_grating_unit_price: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
      u_shaped_gutter_grating_length: {
        onBlur: onBlurForCostBuildingRelatedCost,
      },
    },
    costsalescost: {
      joint_mediation_cost: {
        onBlur: onBlurForCostSalesCost,
      },
      resale_fee: {
        onBlur: onBlurForCostSalesCost,
      },
    },
  }), [onBlurForCostLandRelatedCostLandAcquisitionCost, onBlurForCostLandRelatedCostOther, onBlurForCostLandRelatedCost, onBlurForCostBuildingRelatedCostOther, onBlurForCostBuildingRelatedCost, onBlurForCostSalesCost]);

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [isMobile])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms title="費用">
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={isOpenLandRelatedForm}
          handleClose={() => {
            setIsOpenLandRelatedForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="土地関係費"
          contentSx={{ px: 0, pt: 0 }}
          content={
            <LandRelatedForm
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              disabled={props.disabled}
              isModal={true}
              property={property}
              onBlurForCostLandRelatedCostOther={onBlurForCostLandRelatedCostOther}
              createCostLandRelatedCostOtherForm={createCostLandRelatedCostOtherForm}
              handleRemoveForCostLandRelatedCostOther={handleRemoveForCostLandRelatedCostOther}
              MAX_ROW_COST_LAND_RELATED_COST_OTHER={costlandrelatedcost.MAX_ROW_COST_LAND_RELATED_COST_OTHER ?? 0}
              costLandRelatedCostOtherFields={costLandRelatedCostOtherFields}
              costLandRelatedCostLandAcquisitionCostFields={costLandRelatedCostLandAcquisitionCostFields}
              setValue={setValue}
              resetForCostLandRelatedCost={resetForCostLandRelatedCost}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenBuildingsRelatedForm}
          handleClose={() => {
            setIsOpenBuildingsRelatedForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="建物関係費"
          contentSx={{ px: 0, pt: 0 }}
          content={
            <BuildingsRelatedForm
              isEditableBasicBuildingsRelatedCount={costbuildingrelatedcost?.is_editable_basic_buildings_related_count}
              isEditableAddingExteriorCount={costbuildingrelatedcost?.is_editable_adding_exterior_count}
              isEditableWaterworksBureauPaymentCount={costbuildingrelatedcost?.is_editable_waterworks_bureau_payment_count}
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              isModal={true}
              property={property}
              basicBuildingsRelatedFields={basicBuildingsRelatedFields}
              waterworksBureauPaymentFields={waterworksBureauPaymentFields}
              createCostBuildingRelatedCostOtherForm={createCostBuildingRelatedCostOtherForm}
              handleRemoveForCostBuildingRelatedCostOther={handleRemoveForCostBuildingRelatedCostOther}
              MAX_ROW_COST_BUILDING_RELATED_COST_OTHER={costbuildingrelatedcost.MAX_ROW_COST_BUILDING_RELATED_COST_OTHER ?? 0}
              costBuildingRelatedCostOtherFields={costBuildingRelatedCostOtherFields}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenSalesCostForm}
          handleClose={() => {
            setIsOpenSalesCostForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="販管費"
          contentSx={{ px: 0, pt: 0 }}
          content={
            <SalesCostForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              isModal={true}
            />
          }
        />
        <form>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} lg={6}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenLandRelatedForm(true))}
              >
                <Grid container direction="row" spacing={1} alignItems="center" >
                  <Grid item xs={12}>
                    <CostFormsHeader title="土地関係費" />
                  </Grid>
                  <Grid item xs={12}>
                    <LandRelatedForm
                      commonInputProps={commonInputProps}
                      rules={rules}
                      choices={choices}
                      disabled={props.disabled}
                      isModal={false}
                      property={property}
                      onBlurForCostLandRelatedCostOther={onBlurForCostLandRelatedCostOther}
                      createCostLandRelatedCostOtherForm={createCostLandRelatedCostOtherForm}
                      handleRemoveForCostLandRelatedCostOther={handleRemoveForCostLandRelatedCostOther}
                      MAX_ROW_COST_LAND_RELATED_COST_OTHER={costlandrelatedcost.MAX_ROW_COST_LAND_RELATED_COST_OTHER ?? 0}
                      costLandRelatedCostOtherFields={costLandRelatedCostOtherFields}
                      costLandRelatedCostLandAcquisitionCostFields={costLandRelatedCostLandAcquisitionCostFields}
                      setValue={setValue}
                      resetForCostLandRelatedCost={resetForCostLandRelatedCost}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Stack spacing={3}>
                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenBuildingsRelatedForm(true))}
                >
                  <Grid container direction="row" spacing={1} alignItems="center" >
                    <Grid item xs={12}>
                      <CostFormsHeader title="建物関係費" />
                    </Grid>
                    <Grid item xs={12}>
                      <BuildingsRelatedForm
                        isEditableBasicBuildingsRelatedCount={costbuildingrelatedcost?.is_editable_basic_buildings_related_count}
                        isEditableAddingExteriorCount={costbuildingrelatedcost?.is_editable_adding_exterior_count}
                        isEditableWaterworksBureauPaymentCount={costbuildingrelatedcost?.is_editable_waterworks_bureau_payment_count}
                        commonInputProps={commonInputProps}
                        rules={rules}
                        disabled={props.disabled}
                        isModal={false}
                        property={property}
                        basicBuildingsRelatedFields={basicBuildingsRelatedFields}
                        waterworksBureauPaymentFields={waterworksBureauPaymentFields}
                        createCostBuildingRelatedCostOtherForm={createCostBuildingRelatedCostOtherForm}
                        handleRemoveForCostBuildingRelatedCostOther={handleRemoveForCostBuildingRelatedCostOther}
                        MAX_ROW_COST_BUILDING_RELATED_COST_OTHER={costbuildingrelatedcost.MAX_ROW_COST_BUILDING_RELATED_COST_OTHER ?? 0}
                        costBuildingRelatedCostOtherFields={costBuildingRelatedCostOtherFields}
                      />
                    </Grid>
                  </Grid>
                </Paper>

                <Paper
                  elevation={formPaperElevation}
                  variant={formPaperVariant}
                  onClick={() => formAreaClickedAction(() => setIsOpenSalesCostForm(true))}
                >
                  <Grid container direction="row" spacing={1} alignItems="center" >
                    <Grid item xs={12}>
                      <CostFormsHeader title="販管費" />
                    </Grid>
                    <Grid item xs={12}>
                      <SalesCostForm
                        commonInputProps={commonInputProps}
                        rules={rules}
                        disabled={props.disabled}
                        isModal={false}
                      />
                    </Grid>
                  </Grid>
                </Paper>

              </Stack>

            </Grid>

          </Grid>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});

CostForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  costlandrelatedcost: PropTypes.object.isRequired,
  costbuildingrelatedcost: PropTypes.object.isRequired,
  costsalescost: PropTypes.object.isRequired,
  businessbalance: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

CostForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const LandRelatedForm = React.memo(function landRelatedForm(props) {

  const { commonInputProps, rules, choices, costLandRelatedCostLandAcquisitionCostFields, createCostLandRelatedCostOtherForm, handleRemoveForCostLandRelatedCostOther, property, MAX_ROW_COST_LAND_RELATED_COST_OTHER, costLandRelatedCostOtherFields, isModal, setValue, resetForCostLandRelatedCost } = props

  const user = React.useContext(UserContext);
  const getData = () => {
    let data = []
    costLandRelatedCostLandAcquisitionCostFields.forEach((item, index) => {
      const number = index === 0
      data.push({
        number: number,
        title: `土地取得費${index + 1}`,
        inputs: <Grid container spacing={1}>
          <HiddenInput
            {...commonInputProps}
            name={`costlandrelatedcost.costlandrelatedcostlandacquisitioncost_set.${index}.id`}
          />
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name={`costlandrelatedcost.costlandrelatedcostlandacquisitioncost_set.${index}.land_acquisition_cost`}
              label={isModal ? "金額" : undefined}
              rules={
                rules.costlandrelatedcost
                  .costlandrelatedcostlandacquisitioncost_set
                  .land_acquisition_cost
              }
              type="number"
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      })
    })
    data.push(
      {
        number: false,
        title: "土地取得費合計",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.land_acquisition_cost_sum"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "固定資産税負担",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.fixed_asset_tax_burden_amount"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.fixed_asset_tax_burden_unit_price"
              type="number"
              rules={rules.costlandrelatedcost.fixed_asset_tax_burden_unit_price}
              disabled={!user.has_special_editing_capability}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.fixed_asset_tax_burden_count"
                  type="number"
                  rules={rules.costlandrelatedcost.fixed_asset_tax_burden_count}
                  disabled={!user.has_special_editing_capability}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">棟</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "登記料(報酬+立替)",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.registration_charge_reward_advances_amount"
              type="number"
              rules={rules.costlandrelatedcost.registration_charge_reward_advances_amount}
              disabled={!user.has_special_editing_capability}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "登記料(登録免許料)",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.registration_charge_registration_licenses_tax"
              type="number"
              rules={rules.costlandrelatedcost.registration_charge_registration_licenses_tax}
              disabled={!user.has_special_editing_capability}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "印紙税(契約時印紙)",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.stamp_tax_contract_when_revenue_stamp"
              rules={rules.costlandrelatedcost.stamp_tax_contract_when_revenue_stamp}
              type="number"
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "仕入仲介手数料",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.purchase_intermediary_charge"
              rules={rules.costlandrelatedcost.purchase_intermediary_charge}
              type="number"
              disabled={props.disabled}
              resetAction={resetForCostLandRelatedCost}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "現況測量・分筆料(報酬)",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.current_state_meas_subdiv_chg_reward"
              type="number"
              disabled={!user.has_special_editing_capability}
              rules={rules.costlandrelatedcost.current_state_meas_subdiv_chg_reward}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 7 : 5}>
            <TextField
              {...commonInputProps}
              label="確定測量費"
              labelPosition="left"
              name="costlandrelatedcost.fixed_measurement_expenses"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 5 : 3}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <SelectForm
                  {...commonInputProps}
                  name="costlandrelatedcost.current_state_measurement_subdivision_fee_count"
                  rules={
                    rules.costlandrelatedcost
                      .current_state_measurement_subdivision_fee_count
                  }
                  choices={choices?.current_state_measurement_subdivision_fee_count}
                  disabled={props.disabled}
                  setValue={setValue}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "現況測量・分筆料(印紙代)",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.current_state_meas_subdiv_chg_rev_stamp_fee"
              type="number"
              rules={rules.costlandrelatedcost.current_state_meas_subdiv_chg_rev_stamp_fee}
              disabled={!user.has_special_editing_capability}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "業務委託料",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.outsourcing_fee"
              rules={rules.costlandrelatedcost.outsourcing_fee}
              type="number"
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "地主承諾料関係",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.landowner_approval_cost"
              rules={rules.costlandrelatedcost.landowner_approval_cost}
              type="number"
              disabled={props.disabled || property.is_areacode_fukuoka}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color={property.is_areacode_fukuoka ? "gray" : "yellow"}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "地代",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.monthly_land_rent_1year"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={3}>
                <Typography>月額</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.monthly_land_rent"
                  rules={rules.costlandrelatedcost.monthly_land_rent}
                  type="number"
                  disabled={props.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">￥</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.monthly_land_rent_1year_months"
                  type="number"
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">ヵ月</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "借地・立退・但書関係費",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.leased_land_eviction_related_expenses"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "立退費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.moving_out_cost"
              rules={rules.costlandrelatedcost.moving_out_cost}
              type="number"
              disabled={props.disabled || property.is_areacode_fukuoka}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color={property.is_areacode_fukuoka ? "gray" : "yellow"}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "契約不適合責任予備費",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.contract_non_conformity_liability_amount"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.contract_non_conformity_liability_unit_price"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.contract_non_conformity_liability_reserve_fund_count"
                  rules={
                    rules.costlandrelatedcost
                      .contract_non_conformity_liability_reserve_fund_count
                  }
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">棟</InputAdornment>
                    ),
                  }}
                  disabled={props.disabled}
                  color="yellow"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "建物本体解体工事費",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.building_body_demolition_work_amount"
              rules={rules.costlandrelatedcost.building_body_demolition_work_amount}
              type="number"
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.building_body_demolition_work_unit_price"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.building_body_demolition_work_tsubo"
                  type="float"
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">坪</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "造成・宅地調整費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.construction_residential_land_adjustment_cost"
              rules={
                rules.costlandrelatedcost
                  .construction_residential_land_adjustment_cost
              }
              type="number"
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "位置指定・開発工事費",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.position_specified_development_work_cost"
              rules={
                rules.costlandrelatedcost
                  .position_specified_development_work_cost
              }
              type="number"
              disabled={props.disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={3}>
                <Typography>舗装</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.position_specified_development_work_pavement"
                  rules={
                    rules.costlandrelatedcost
                      .position_specified_development_work_pavement
                  }
                  type="number"
                  disabled={props.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">￥</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={3}>
                <Typography>引込</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.position_specified_development_work_lead_in"
                  rules={
                    rules.costlandrelatedcost
                      .position_specified_development_work_lead_in
                  }
                  type="number"
                  disabled={props.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">￥</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "電柱移設",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.power_pole_relocation_amount"
              type="number"
              rules={rules.costlandrelatedcost.power_pole_relocation_amount}
              disabled={!(property.is_areacode_fukuoka)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.power_pole_relocation_unit_price"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.power_pole_relocation_count"
                  rules={
                    rules.costlandrelatedcost.power_pole_relocation_count
                  }
                  type="number"
                  disabled={props.disabled}
                  color="yellow"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "上下水道ガス",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <Grid
              container
              direction="row"
              spacing={1}
              sx={{ minHeight: "100%" }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.waterworks_and_gas_cost"
                  rules={
                    rules.costlandrelatedcost.waterworks_and_gas_cost
                  }
                  type="number"
                  disabled={props.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">￥</InputAdornment>
                    ),
                  }}
                  color="yellow"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <Typography>前面上水本管</Typography>
              </Grid>
              <Grid item xs={6}>
                <SelectForm
                  {...commonInputProps}
                  name="costlandrelatedcost.front_clean_water_main"
                  rules={rules.costlandrelatedcost.front_clean_water_main}
                  choices={choices?.availability}
                  disabled={props.disabled}
                  setValue={setValue}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography>前面ガス配管</Typography>
              </Grid>
              <Grid item xs={6}>
                <SelectForm
                  {...commonInputProps}
                  name="costlandrelatedcost.front_gas_plumbing"
                  rules={rules.costlandrelatedcost.front_gas_plumbing}
                  choices={choices?.availability}
                  disabled={props.disabled}
                  setValue={setValue}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography>名古屋：設計水圧</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.nagoya_design_hydraulic_pressure"
                  rules={
                    rules.costlandrelatedcost
                      .nagoya_design_hydraulic_pressure
                  }
                  type="float"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        mps
                      </InputAdornment>
                    ),
                  }}
                  disabled={props.disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      ...(property.is_areacode_fukuoka ? [
        {
          number : true,
          title: "近隣対策費用",
          inputs : <Grid container spacing={1}>
            <Grid item xs={isModal ? 12 : 4}>
              <TextField
                {...commonInputProps}
                name="costlandrelatedcost.neighborhood_control_amount"
                type="number"
                rules={rules.costlandrelatedcost.neighborhood_control_amount}
                disabled={props.disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={isModal ? 6 : 4}>
              <TextField
                {...commonInputProps}
                name="costlandrelatedcost.neighborhood_control_unit_price"
                type="number"
                rules={rules.costlandrelatedcost.neighborhood_control_unit_price}
                disabled={props.disabled || property.is_areacode_gunma || property.is_areacode_capital_area}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={isModal ? 6 : 4}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={2}>
                  <XText />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    {...commonInputProps}
                    name="costlandrelatedcost.neighborhood_control_count"
                    rules={rules.costlandrelatedcost.neighborhood_control_count}
                    type="float"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">棟</InputAdornment>
                      ),
                    }}
                    disabled={props.disabled}
                    color="yellow"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        }, {
          number : true,
          title: "ブロックフェンス",
          inputs : <Grid container spacing={1}>
            <Grid item xs={isModal ? 12 : 4}>
              <TextField
                {...commonInputProps}
                name="costlandrelatedcost.block_fence_amount"
                rules={rules.costlandrelatedcost.block_fence_amount}
                type="number"
                disabled={props.disabled || !(property.is_areacode_fukuoka)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={isModal ? 6 : 4}>
              <TextField
                {...commonInputProps}
                name="costlandrelatedcost.block_fence_unit_price"
                type="number"
                rules={rules.costlandrelatedcost.block_fence_unit_price}
                disabled={props.disabled || property.is_areacode_gunma || property.is_areacode_capital_area}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">￥</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={isModal ? 6 : 4}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={2}>
                  <XText />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    {...commonInputProps}
                    name="costlandrelatedcost.block_fence_length"
                    rules={rules.costlandrelatedcost.block_fence_length}
                    type="float"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m</InputAdornment>
                      ),
                    }}
                    disabled={props.disabled}
                    color="yellow"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }] : [{
        number: true,
        title: "ブロックフェンス＋近隣対策",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.block_fence_neighborhood_control_amount"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.block_fence_unit_price"
              type="number"
              rules={rules.costlandrelatedcost.block_fence_unit_price}
              disabled={props.disabled || property.is_areacode_gunma || property.is_areacode_capital_area}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.block_fence_length"
                  rules={rules.costlandrelatedcost.block_fence_length}
                  type="float"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">m</InputAdornment>
                    ),
                  }}
                  disabled={props.disabled}
                  color="yellow"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }]),
      {
        number: true,
        title: "擁壁費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.retaining_wall_cost"
              rules={rules.costlandrelatedcost.retaining_wall_cost}
              type="number"
              disabled={props.disabled || !(property.is_areacode_nagoya || property.is_areacode_gunma || property.is_areacode_capital_area || property.is_areacode_fukuoka)}
              color="yellow"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "舗装・SB・切下費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.pavement_sb_off_lower_cost"
              rules={rules.costlandrelatedcost.pavement_sb_off_lower_cost}
              type="number"
              disabled={props.disabled || !(property.is_areacode_nagoya || property.is_areacode_gunma || property.is_areacode_capital_area || property.is_areacode_fukuoka)}
              color="yellow"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "ガードマン費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.guardman_cost"
              rules={rules.costlandrelatedcost.guardman_cost}
              type="number"
              disabled={props.disabled || !(property.is_areacode_nagoya || property.is_areacode_gunma || property.is_areacode_capital_area || property.is_areacode_fukuoka)}
              color="yellow"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "埋蔵文化材発掘費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.buried_cultural_property_exaction_cost"
              rules={
                rules.costlandrelatedcost
                  .buried_cultural_property_exaction_cost
              }
              type="number"
              disabled={props.disabled || !(property.is_areacode_fukuoka || property.is_areacode_gunma || property.is_areacode_capital_area)}
              color="yellow"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 8}>
            <NoNameTextField />
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "浄化槽設置費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.septic_tank_amount"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.septic_tank_unit_price"
              type="number"
              disabled={true}
              color="yellow"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.septic_tank_count"
                  rules={rules.costlandrelatedcost.septic_tank_count}
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">棟</InputAdornment>
                    ),
                  }}
                  disabled={props.disabled || !(property.is_areacode_gunma || property.is_areacode_capital_area)}
                  color="yellow"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
      {
        number: true,
        title: "雨水浸透マス費用",
        inputs: <Grid container spacing={1}>
          <Grid item xs={isModal ? 12 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.rainwater_penetration_trout_amount"
              type="number"
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <TextField
              {...commonInputProps}
              name="costlandrelatedcost.rainwater_penetration_trout_unit_price"
              type="number"
              disabled={true}
              color="yellow"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 4}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={2}>
                <XText />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  {...commonInputProps}
                  name="costlandrelatedcost.rainwater_penetration_trout_count"
                  rules={
                    rules.costlandrelatedcost
                      .rainwater_penetration_trout_count
                  }
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">棟</InputAdornment>
                    ),
                  }}
                  disabled={props.disabled || !(property.is_areacode_gunma || property.is_areacode_capital_area)}
                  color="yellow"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      },
    )
    return data
  }

  const getForms = () => {
    var count = 0
    const forms = getData().map((item, index) => {
      const isNumber = item.number === true
      if (isNumber) {
        count++
      }
      return (
        <Grid item container xs={12} key={index} alignItems="center">
          {
            isModal
            ?
              <DialogSubContent
                title={
                  <Stack direction="row" spacing={2}>
                    {isNumber && <Typography>{`(${count})`}</Typography> }
                    <Typography>
                      {item.title}
                    </Typography>
                  </Stack>
                }
                content={item.inputs}
              />
            :
              <>
                {isNumber
                  ?
                  <Grid item xs={1}>{`(${count})`}</Grid>
                  :
                  <Grid item xs={1} />
                }
                <Grid item xs={2}>
                  <Typography>{item.title}</Typography>
                </Grid>
                <Grid item xs={9}>
                  {item.inputs}
                </Grid>
              </>
          }
        </Grid>
      )
    })
    return forms
  }

  return (
    <Grid container direction="row" spacing={1}>

      { getForms().map((item) => { return (item)}) }

      <Grid item xs={12}>
        <CostOtherForm
          isModal={isModal}
          commonInputProps={commonInputProps}
          addForm={createCostLandRelatedCostOtherForm}
          removeForm={handleRemoveForCostLandRelatedCostOther}
          fields={costLandRelatedCostOtherFields}
          tableName="costlandrelatedcostother_set"
          parentTableName="costlandrelatedcost"
          rules={rules}
          startNum={27}
          property={property}
          MAX_ROW={MAX_ROW_COST_LAND_RELATED_COST_OTHER ?? 0}
        />
      </Grid>

      <Grid item xs={12} container direction="row" spacing={1}>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <Typography variant="subtitle1">小計：</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextField
            {...commonInputProps}
            name="costlandrelatedcost.land_related_cost_subtotal"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} />
      </Grid>
    </Grid>
  )
})
LandRelatedForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  costLandRelatedCostOtherFields: PropTypes.array.isRequired,
  MAX_ROW_COST_LAND_RELATED_COST_OTHER: PropTypes.number.isRequired,
  costLandRelatedCostLandAcquisitionCostFields: PropTypes.array.isRequired,
  onBlurForCostLandRelatedCostOther: PropTypes.func.isRequired,
  createCostLandRelatedCostOtherForm: PropTypes.func.isRequired,
  handleRemoveForCostLandRelatedCostOther: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  resetForCostLandRelatedCost: PropTypes.func.isRequired,
}

const BuildingsRelatedForm = React.memo(function buildingsRelatedForm(props) {

  const { commonInputProps, rules, basicBuildingsRelatedFields, waterworksBureauPaymentFields, createCostBuildingRelatedCostOtherForm, handleRemoveForCostBuildingRelatedCostOther, property, MAX_ROW_COST_BUILDING_RELATED_COST_OTHER, costBuildingRelatedCostOtherFields, isModal, isEditableBasicBuildingsRelatedCount, isEditableAddingExteriorCount, isEditableWaterworksBureauPaymentCount} = props

  return (
    <Grid container direction="row" spacing={1}>

      {basicBuildingsRelatedFields.map((item, index) => {
        const floor = `${item.floor}${item.floor && "F"}`
        const title = `基本建物関係費${floor}`
        return (
          <CostBuildingRelatedCostForm
            key={item.id}
            isModal={isModal}
            number={index === 0 ? 1 : ""}
            title={title}
            amountParams={{
              ...commonInputProps,
              name: `costbuildingrelatedcost.basic_buildings_related.${index}.basic_buildings_related_amount`,
              type: "number",
              disabled: true,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }
            }}
            unitPriceParams={{
              ...commonInputProps,
              name: `costbuildingrelatedcost.basic_buildings_related.${index}.basic_buildings_related_unit_price`,
              type: "number",
              disabled: true,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }
            }}
            quantityParams={{
              ...commonInputProps,
              name: `costbuildingrelatedcost.basic_buildings_related.${index}.basic_buildings_related_count`,
              type: "number",
              rules: rules.costbuildingrelatedcost.basic_buildings_related_count,
              disabled: props.disabled || !isEditableBasicBuildingsRelatedCount,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">棟</InputAdornment>
                ),
              },
            }}
          />
        )
      })}

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={2}
        title="ビルドイン車庫"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.build_in_garage_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          },
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.build_in_garage_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.build_in_garage_tsubo",
          rules: rules.costbuildingrelatedcost.build_in_garage_tsubo,
          type: "float",
          disabled: true,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">坪</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={3}
        title="ルーフバルコニー"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.roof_balcony_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.roof_balcony_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.roof_balcony_count",
          type: "number",
          disabled: true,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={4}
        title="敷地延長費(5m超)"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.site_extended_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.site_extended_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.site_extended_length",
          rules: rules.costbuildingrelatedcost.site_extended_length,
          type: "float",
          disabled: props.disabled,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">m</InputAdornment>
            ),
          },
          color: "yellow",
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={5}
        title="追加外構費"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.adding_exterior_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.adding_exterior_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.adding_exterior_count",
          type: "number",
          rules: rules.costbuildingrelatedcost.adding_exterior_count,
          disabled: props.disabled || !isEditableAddingExteriorCount,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={6}
        title="防音ガラス"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.soundproof_glass_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.soundproof_glass_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.soundproof_glass_count",
          rules: rules.costbuildingrelatedcost.soundproof_glass_count,
          type: "number",
          disabled: props.disabled,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
          color: "yellow",
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={7}
        title="本体建築工事費"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.body_building_work_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.body_building_work_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.body_building_work_tsubo",
          type: "float",
          disabled: true,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">坪</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={8}
        title="小運搬費(狭隘4m未満)"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.narrow_less_than4m_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.narrow_less_than4m_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.narrow_less_than_4m_tsubo",
          rules:
            rules.costbuildingrelatedcost.narrow_less_than_4m_tsubo,
          type: "number",
          disabled: props.disabled,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
          color: "yellow",
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={9}
        title="小運搬費(手運20m迄)"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.hand_carrying20m_under_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.hand_carrying_20m_under_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.hand_carrying_20m_under_tsubo",
          rules:
            rules.costbuildingrelatedcost
              .hand_carrying_20m_under_tsubo,
          type: "number",
          disabled: props.disabled,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
          color: "yellow",
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number={10}
        title="持上運搬費"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.lifting_transportation_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.lifting_transportation_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.lifting_transportation_tsubo",
          rules:
            rules.costbuildingrelatedcost
              .lifting_transportation_tsubo,
          type: "number",
          disabled: props.disabled,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
          color: "yellow",
        }}
      />

      {waterworksBureauPaymentFields.map((item, index) => {
        const floor = `${item.floor}${item.floor && "F"}`
        const title = `水道局納入金${floor}`
        return (
          <CostBuildingRelatedCostForm
            key={item.id}
            isModal={isModal}
            number={index === 0 ? 11 : ""}
            title={title}
            amountParams={{
              ...commonInputProps,
              name: `costbuildingrelatedcost.waterworks_bureau_payment.${index}.waterworks_bureau_payment_amount`,
              type: "number",
              disabled: true,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }
            }}
            unitPriceParams={{
              ...commonInputProps,
              name: `costbuildingrelatedcost.waterworks_bureau_payment.${index}.waterworks_bureau_payment_unit_price`,
              type: "number",
              disabled: true,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">￥</InputAdornment>
                ),
              }
            }}
            quantityParams={{
              ...commonInputProps,
              name: `costbuildingrelatedcost.waterworks_bureau_payment.${index}.waterworks_bureau_payment_count`,
              type: "number",
              rules: rules.costbuildingrelatedcost.waterworks_bureau_payment_count,
              disabled: props.disabled || !isEditableWaterworksBureauPaymentCount,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">棟</InputAdornment>
                ),
              },
            }}
          />
        )
      })}

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number="名"
        title="ポーチ費用"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.porch_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.porch_unit_price",
          rules: rules.costbuildingrelatedcost.porch_unit_price,
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.porch_count",
          rules: rules.costbuildingrelatedcost.porch_count,
          type: "number",
          disabled: props.disabled || !property.is_areacode_nagoya,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number="名福"
        title="高低差処理(基礎)費用"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.vertical_interval_process_basic_amount",
          type: "number",
          rules:
            rules.costbuildingrelatedcost
              .vertical_interval_process_basic_amount,
          disabled: props.disabled || (!property.is_areacode_nagoya && !property.is_areacode_fukuoka),
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.vertical_interval_process_basic_unit_price",
          type: "number",
          rules:
            rules.costbuildingrelatedcost
              .vertical_interval_process_basic_unit_price,
          disabled: props.disabled || (!property.is_areacode_nagoya && !property.is_areacode_fukuoka),
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.vertical_interval_processing_basic_tsubo",
          rules:
            rules.costbuildingrelatedcost
              .vertical_interval_processing_basic_tsubo,
          type: "number",
          disabled: props.disabled || (!property.is_areacode_nagoya && !property.is_areacode_fukuoka),
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number="福"
        title={property.is_areacode_fukuoka ? "オプション諸費用" : "床暖房費用"}
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.floor_heating_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.floor_heating_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.floor_heating_tsubo",
          rules: rules.costbuildingrelatedcost.floor_heating_tsubo,
          type: "number",
          disabled: props.disabled || !property.is_areacode_fukuoka,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number="福"
        title="高額帯OP（5,000以上）"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.bathroom_dryer_machine_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.bathroom_dryer_machine_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.bathroom_dryer_machine_tsubo",
          rules:
            rules.costbuildingrelatedcost
              .bathroom_dryer_machine_tsubo,
          type: "number",
          disabled: props.disabled || !property.is_areacode_fukuoka,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number="福"
        title="防火対応費用"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.fireproof_supported_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.fireproof_supported_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.fireproof_supported_tsubo",
          rules:
            rules.costbuildingrelatedcost.fireproof_supported_tsubo,
          type: "number",
          disabled: props.disabled || !property.is_areacode_fukuoka,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number="福"
        title="外注設計費用"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.outsourcing_design_amount",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.outsourcing_design_unit_price",
          type: "number",
          disabled: true,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.outsourcing_design_tsubo",
          rules:
            rules.costbuildingrelatedcost.outsourcing_design_tsubo,
          type: "number",
          disabled: props.disabled || !property.is_areacode_fukuoka,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">棟</InputAdornment>
            ),
          },
        }}
      />

      <CostBuildingRelatedCostForm
        isModal={isModal}
        number="関西"
        title="U字側溝/グレーチング"
        amountParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.u_shaped_gutter_grating_amount",
          rules: rules.costbuildingrelatedcost.u_shaped_gutter_grating_amount,
          type: "number",
          disabled: props.disabled || !property.is_areacode_kansai,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        unitPriceParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.u_shaped_gutter_grating_unit_price",
          rules: rules.costbuildingrelatedcost.u_shaped_gutter_grating_unit_price,
          type: "number",
          disabled: props.disabled || !property.is_areacode_kansai,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }
        }}
        quantityParams={{
          ...commonInputProps,
          name: "costbuildingrelatedcost.u_shaped_gutter_grating_length",
          rules:
            rules.costbuildingrelatedcost
              .u_shaped_gutter_grating_length,
          type: "number",
          disabled: props.disabled || !property.is_areacode_kansai,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">m</InputAdornment>
            ),
          },
        }}
      />
      <Grid item xs={12}>
        <CostOtherForm
          commonInputProps={commonInputProps}
          addForm={createCostBuildingRelatedCostOtherForm}
          removeForm={handleRemoveForCostBuildingRelatedCostOther}
          fields={costBuildingRelatedCostOtherFields}
          tableName="costbuildingrelatedcostother_set"
          parentTableName="costbuildingrelatedcost"
          rules={rules}
          startNum={14}
          property={property}
          MAX_ROW={MAX_ROW_COST_BUILDING_RELATED_COST_OTHER}
          isModal={isModal}
        />
      </Grid>

      <Grid item xs={1} />
      <Grid item xs={2}>
        <Typography variant="subtitle1">小計：</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          {...commonInputProps}
          name="costbuildingrelatedcost.building_related_cost_subtotal"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6} />

    </Grid>
  )
})

BuildingsRelatedForm.defaultProps = {
  isEditableBasicBuildingsRelatedCount: false,
  isEditableAddingExteriorCount: false,
  isEditableWaterworksBureauPaymentCount: false,
}
BuildingsRelatedForm.propTypes = {
  isEditableBasicBuildingsRelatedCount: PropTypes.bool,
  isEditableAddingExteriorCount: PropTypes.bool,
  isEditableWaterworksBureauPaymentCount: PropTypes.bool,
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  costBuildingRelatedCostOtherFields: PropTypes.array.isRequired,
  MAX_ROW_COST_BUILDING_RELATED_COST_OTHER: PropTypes.number.isRequired,
  basicBuildingsRelatedFields: PropTypes.array.isRequired,
  waterworksBureauPaymentFields: PropTypes.array.isRequired,
  createCostBuildingRelatedCostOtherForm: PropTypes.func.isRequired,
  handleRemoveForCostBuildingRelatedCostOther: PropTypes.func.isRequired,
}

const SalesCostForm = React.memo(function salesCostForm(props) {

  const { commonInputProps, rules, isModal} = props

  const user = React.useContext(UserContext);
  const data = [
    {
      number: true,
      title: "再販手数料",
      inputs: <Grid container spacing={1}>
        <Grid item xs={isModal ? 12 : 4}>
          <TextField
            {...commonInputProps}
            name="costsalescost.resale_fee"
            type="number"
            rules={rules.costsalescost.resale_fee}
            disabled={!user.has_special_editing_capability}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <NoNameTextField />
        </Grid>
      </Grid>
    },
    {
      number: true,
      title: "共同仲介",
      inputs: <Grid container spacing={1}>
        <Grid item xs={isModal ? 12 : 4}>
          <TextField
            {...commonInputProps}
            name="costsalescost.joint_mediation_cost"
            rules={rules.costsalescost.joint_mediation_cost}
            type="number"
            disabled={props.disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            color="yellow"
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <NoNameTextField />
        </Grid>
      </Grid>
    },
    {
      number: true,
      title: "雑費",
      inputs: <Grid container spacing={1}>
        <Grid item xs={isModal ? 12 : 4}>
          <TextField
            {...commonInputProps}
            name="costsalescost.miscellaneous_expenses"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={isModal ? 12 : 8}>
          <NoNameTextField />
        </Grid>
      </Grid>
    },
    {
      number: true,
      title: "借入利息",
      inputs: <Grid container spacing={1}>
        <Grid item xs={isModal ? 12 : 4}>
          <TextField
            {...commonInputProps}
            name="costsalescost.loan_interest_amount"
            type="float"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={isModal ? 2 : 1}>
          <Typography>金利</Typography>
        </Grid>
        <Grid item xs={isModal ? 4 : 3}>
          <TextField
            {...commonInputProps}
            name="costsalescost.loan_interest_rate"
            type="float"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">%</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={isModal ? 2 : 1}>
          <Typography>期間</Typography>
        </Grid>
        <Grid item xs={isModal ? 4 : 3}>
          <TextField
            {...commonInputProps}
            name="costsalescost.loan_interest_term"
            type="number"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">ヵ月</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    },
  ]

  const getForms = () => {
    var count = 0
    const forms = data.map((item, index) => {
      const isNumber = item.number === true
      if (isNumber) {
        count++
      }
      return (
        <Grid item container xs={12} key={index} alignItems="center">
          {
            isModal
            ?
              <DialogSubContent
                title={
                  <Stack direction="row" spacing={2}>
                    {isNumber && <Typography>{`(${count})`}</Typography> }
                    <Typography>
                      {item.title}
                    </Typography>
                  </Stack>
                }
                content={item.inputs}
              />
            :
              <>
                {isNumber
                  ?
                  <Grid item xs={1}>{`(${count})`}</Grid>
                  :
                  <Grid item xs={1} />
                }
                <Grid item xs={2}>
                  <Typography>{item.title}</Typography>
                </Grid>
                <Grid item xs={9}>
                  {item.inputs}
                </Grid>
              </>
          }
        </Grid>
      )
    })
    return forms
  }

  return (
    <Grid container direction="row" spacing={1} alignItems="center" >

      { getForms().map((item) => { return (item)}) }

      <Grid item xs={1} />
      <Grid item xs={2}>
        <Typography variant="subtitle1">小計：</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          {...commonInputProps}
          name="costsalescost.sales_management_expenses_subtotal"
          type="number"
          disabled={true}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">￥</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6} />
    </Grid>
  )
})
SalesCostForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
}