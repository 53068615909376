import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, Paper, Typography } from "@mui/material";
import { BusinessProcessForm } from "../molecules/BusinessProcessForm";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitBusinessProcess } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const BusinessProcessForms = React.memo(function businessProcessForms({
  property,
  initialized,
  businessprocess,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {

  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [isOpenForms, setIsOpenForms] = React.useState(false)
  const [propertyId, setPropertyId] = React.useState(null)

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  const {
    control,
    formState: { errors },
    setValue,
    methods,
    setError,
    clearErrors,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  useInitBusinessProcess(businessprocess, setValue, isFocused)

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  React.useEffect(() => {
    if (!businessprocess) {
      return;
    }
    initialized();
  }, [businessprocess]);

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const onBlurForBusinessProcess = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "businessprocess",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const rules = React.useMemo(() => ({
    businessprocess: {
      contract_at: {
        onBlur: onBlurForBusinessProcess,
      },
      settlement_at: {
        onBlur: onBlurForBusinessProcess,
      },
      fixed_measurement_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      fixed_measurement_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      narrow_agreement_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      narrow_agreement_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      high_low_north_state_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      high_low_north_state_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      passing_extension_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      passing_extension_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      dismantling_announcement_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      dismantling_announcement_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      dismantling_body_work_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      dismantling_body_work_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      specified_position_new_found_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      specified_position_new_found_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      development_act_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      development_act_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      construction_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      construction_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      landscaping_work_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      landscaping_work_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      land_passing_announcement_at: {
        onBlur: onBlurForBusinessProcess,
      },
      land_passing_at: {
        onBlur: onBlurForBusinessProcess,
      },
      building_confirmation_application_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      building_confirm_application_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      building_work_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      building_work_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      building_passing_announcement_at: {
        onBlur: onBlurForBusinessProcess,
      },
      building_passing_at: {
        onBlur: onBlurForBusinessProcess,
      },
      eviction_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      eviction_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
      resale_full_time_start_at: {
        onBlur: onBlurForBusinessProcess,
      },
      resale_full_time_complete_at: {
        onBlur: onBlurForBusinessProcess,
      },
    },
  }), [onBlurForBusinessProcess]);

  const labels = React.useMemo(() => ({
    standard: "日数",
    start: "開始時期",
    end: "完了時期",
  }), [])

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms title="事業工程">
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={isOpenForms}
          contentSx={{ px: 0, pt: 0 }}
          handleClose={() => {
            setIsOpenForms(false)
            propertyEditFormDialogCloseAction()
          }}
          title="事業工程"
          openedAction={loadingComplete}
          content={
            <Forms
              commonInputProps={commonInputProps}
              labels={labels}
              rules={rules}
              disabled={props.disabled}
              isModal={true}
              property={property}
            />
          }
        />
        <form>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <Typography align="center">{labels.standard}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="center">{labels.start}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="center">{labels.end}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenForms(true))}
              >
                <Forms
                  commonInputProps={commonInputProps}
                  labels={labels}
                  rules={rules}
                  disabled={props.disabled}
                  isModal={false}
                  property={property}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});

BusinessProcessForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  businessprocess: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

BusinessProcessForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const Forms = React.memo(function forms(props) {
  const {commonInputProps, rules, isModal, labels, property} = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="契約・決済"
          standardTextFieldName="businessprocess.days_from_contract_at_to_settlement_at"
          startAtName="businessprocess.contract_at"
          startAtRules={rules.businessprocess.contract_at}
          endAtName="businessprocess.settlement_at"
          endAtRules={rules.businessprocess.settlement_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="確定測量"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_fixed_measurement"
          startAtName="businessprocess.fixed_measurement_start_at"
          startAtRules={rules.businessprocess.fixed_measurement_start_at}
          endAtName="businessprocess.fixed_measurement_complete_at"
          endAtRules={rules.businessprocess.fixed_measurement_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="狭隘協議"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_narrow_agreement"
          startAtName="businessprocess.narrow_agreement_start_at"
          startAtRules={rules.businessprocess.narrow_agreement_start_at}
          endAtName="businessprocess.narrow_agreement_complete_at"
          endAtRules={rules.businessprocess.narrow_agreement_complete_at}
          disabled={props.disabled || property.is_areacode_fukuoka}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="高低真北現況"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_high_low_north_state"
          startAtName="businessprocess.high_low_north_state_start_at"
          startAtRules={
            rules.businessprocess
              .high_low_north_state_start_at
          }
          endAtName="businessprocess.high_low_north_state_complete_at"
          endAtRules={
            rules.businessprocess
              .high_low_north_state_complete_at
          }
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="引渡猶予"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_passing_extension"
          startAtName="businessprocess.passing_extension_start_at"
          startAtRules={rules.businessprocess.passing_extension_start_at}
          endAtName="businessprocess.passing_extension_complete_at"
          endAtRules={rules.businessprocess.passing_extension_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="解体告知"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_dismantling_announcement"
          startAtName="businessprocess.dismantling_announcement_start_at"
          startAtRules={
            rules.businessprocess.dismantling_announcement_start_at
          }
          endAtName="businessprocess.dismantling_announcement_complete_at"
          endAtRules={
            rules.businessprocess.dismantling_announcement_complete_at
          }
          disabled={props.disabled || property.is_areacode_fukuoka}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="解体本体工事"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_dismantling_body_work"
          startAtName="businessprocess.dismantling_body_work_start_at"
          startAtRules={
            rules.businessprocess.dismantling_body_work_start_at
          }
          endAtName="businessprocess.dismantling_body_work_complete_at"
          endAtRules={
            rules.businessprocess.dismantling_body_work_complete_at
          }
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="位置指定新設"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_specified_position_new_found"
          startAtName="businessprocess.specified_position_new_found_start_at"
          startAtRules={
            rules.businessprocess.specified_position_new_found_start_at
          }
          endAtName="businessprocess.specified_position_new_found_complete_at"
          endAtRules={
            rules.businessprocess.specified_position_new_found_complete_at
          }
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="開発行為"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_development_act"
          startAtName="businessprocess.development_act_start_at"
          startAtRules={rules.businessprocess.development_act_start_at}
          endAtName="businessprocess.development_act_complete_at"
          endAtRules={rules.businessprocess.development_act_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="造成"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_construction"
          startAtName="businessprocess.construction_start_at"
          startAtRules={rules.businessprocess.construction_start_at}
          endAtName="businessprocess.construction_complete_at"
          endAtRules={rules.businessprocess.construction_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="外構工事"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_landscaping_work"
          startAtName="businessprocess.landscaping_work_start_at"
          startAtRules={rules.businessprocess.landscaping_work_start_at}
          endAtName="businessprocess.landscaping_work_complete_at"
          endAtRules={rules.businessprocess.landscaping_work_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="発表・土地引渡"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_land_passing"
          startAtName="businessprocess.land_passing_announcement_at"
          startAtRules={
            rules.businessprocess.land_passing_announcement_at
          }
          endAtName="businessprocess.land_passing_at"
          endAtRules={rules.businessprocess.land_passing_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="建築確認申請"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_building_confirmation_application"
          startAtName="businessprocess.building_confirmation_application_start_at"
          startAtRules={
            rules.businessprocess
              .building_confirmation_application_start_at
          }
          endAtName="businessprocess.building_confirm_application_complete_at"
          endAtRules={
            rules.businessprocess
              .building_confirm_application_complete_at
          }
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="建築工事"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_building_work"
          startAtName="businessprocess.building_work_start_at"
          startAtRules={rules.businessprocess.building_work_start_at}
          endAtName="businessprocess.building_work_complete_at"
          endAtRules={rules.businessprocess.building_work_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="発表・建物引渡"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_building_passing_at"
          startAtName="businessprocess.building_passing_announcement_at"
          startAtRules={
            rules.businessprocess.building_passing_announcement_at
          }
          endAtName="businessprocess.building_passing_at"
          endAtRules={rules.businessprocess.building_passing_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="立退"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_eviction"
          startAtName="businessprocess.eviction_start_at"
          startAtRules={rules.businessprocess.eviction_start_at}
          endAtName="businessprocess.eviction_complete_at"
          endAtRules={rules.businessprocess.eviction_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
      <Grid item xs={12}>
        <BusinessProcessForm
          {...commonInputProps}
          title="再販専任期間"
          standardTextFieldName="businessprocess.days_from_start_to_complete_of_resale_full_time"
          startAtName="businessprocess.resale_full_time_start_at"
          startAtRules={rules.businessprocess.resale_full_time_start_at}
          endAtName="businessprocess.resale_full_time_complete_at"
          endAtRules={rules.businessprocess.resale_full_time_complete_at}
          disabled={props.disabled}
          isModal={isModal}
          labels={labels}
        />
      </Grid>
    </Grid>
  )
})
Forms.propTypes = {
  property: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};