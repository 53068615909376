import * as React from "react";
import PropTypes from "prop-types";
import { Stack, TextField as MuiTextField, InputAdornment } from "@mui/material";
import { FormControl } from "../atoms/FormControl";
import { FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import * as validationRules from "../../utils/ValidationRules";
import _ from "lodash";
import { PropertyColumnResetButton } from "../molecules/PropertyColumnResetButton";

export const TextField = (props) => {
  const {
    isLabelInside,
    onFocus,
    fullWidth,
    label,
    name,
    type,
    control,
    rules,
    defaultValue,
    hidden,
    formControlProps,
    inputProps,
    InputProps,
    labelSx,
    labelPosition,
    resetAction,
    forceEmptyValue,
    ...textFiledProps
  } = props
  const defaultLabelSx = labelPosition === "top" ? {} : { whiteSpace: "nowrap" }

  const isNumber = ["number", "float"].includes(type)

  const addValidationRules = type === "float" ? validationRules.isFloat() : validationRules.isNumber()
  const changeRules = isNumber ? { ...rules, ...addValidationRules } : rules
  const changeType = isNumber ? "text" : type

  const changeinputProps = isNumber ? { ...inputProps, style: { textAlign: 'right' } } : inputProps
  const [isFocus, setFocus] = React.useState(false);

  const getDisplayValue = React.useCallback((value) => {
    if (isFocus) {
      return value ?? ""
    }
    if (typeof value === 'number' && isFinite(value)) {
      return _.round(value, props.decimalScale ?? (type === "float" ? 2 : 0)).toLocaleString()
    }
    return value ?? ""
  })

  const handleResetAction = React.useCallback(() => {
    resetAction(name)
  }, [resetAction])

  return (
    <Controller
      name={name}
      control={control}
      rules={changeRules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        let displayValue = field.value;
        if (forceEmptyValue) {
          displayValue = "";
        }
        return (hidden
          ? <MuiTextField
            {...field}
            {...textFiledProps}
            type={type}
            sx={{ display: "none" }}
            fullWidth={fullWidth}
            error={fieldState.error ? true : false}
            helperText={fieldState.error?.message}
            value={type === "file" ? undefined : field.value ?? ""}
            inputProps={inputProps}
            InputProps={InputProps}
            onFocus={() => {
              if (typeof onFocus === "function") {
                onFocus(name)
              }
            }}
          />
          : <FormControl error={fieldState.error ? true : false} fullWidth={fullWidth} {...formControlProps}>
            <Stack
              spacing={labelPosition === "top" ? undefined : 1}
              direction={labelPosition === "top" ? "column" : (labelPosition === "right" ? "row-reverse" : "row")}
              alignItems={labelPosition === "top" ? undefined : "center"}
              justifyContent={labelPosition === "top" ? undefined : "center"}
            >
              {(isLabelInside === false && label) && <FormLabel sx={{ ...defaultLabelSx, ...labelSx }}>{label}</FormLabel>}
              <MuiTextField
                {...field}
                {...textFiledProps}
                label={isLabelInside ? label : undefined}
                type={changeType}
                fullWidth={fullWidth}
                error={fieldState.error ? true : false}
                helperText={fieldState.error?.message}
                value={type === "file" ? undefined : getDisplayValue(displayValue)}
                inputProps={changeinputProps}
                InputProps={{
                  ...InputProps,
                  startAdornment: (
                    <>
                      {InputProps?.startAdornment}
                      {resetAction &&
                        <InputAdornment position="start">
                          <PropertyColumnResetButton onClick={handleResetAction} />
                        </InputAdornment>
                      }
                    </>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => {
                  setFocus(false)
                  if (typeof field.onBlur === "function") {
                    field.onBlur(e)
                  }
                }}
                onFocus={() => {
                  setFocus(true)
                  if (typeof onFocus === "function") {
                    onFocus(name)
                  }
                }}
              />
            </Stack>
          </FormControl>
        )
      }
      }
    />
  )
};

TextField.defaultProps = {
  fullWidth: true,
  size: "small",
  type: "text",
  InputProps: undefined,
  disabled: false,
  value: "",
  hidden: false,
  formControlProps: {},
  labelPosition: "top",
  labelSx: {},
  isLabelInside: false,
  forceEmptyValue: false
};
TextField.propTypes = {
  control: PropTypes.any.isRequired,
  rules: PropTypes.object,
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  InputProps: PropTypes.object,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  hidden: PropTypes.bool,
  formControlProps: PropTypes.object,
  inputProps: PropTypes.object,
  labelSx: PropTypes.object,
  labelPosition: PropTypes.oneOf(["top", "left", "right"]),
  decimalScale: PropTypes.number,
  onFocus: PropTypes.func,
  isLabelInside: PropTypes.bool,
  useReloadButton: PropTypes.bool,
  resetAction: PropTypes.func,
  forceEmptyValue: PropTypes.bool
};

TextField.displayName = "TextField"