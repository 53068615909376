import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Box, Grid, InputAdornment, InputLabel, Paper } from "@mui/material";
import { SelectForm } from "../atoms/SelectForm";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitContractTerm } from "../../utils/CustomHook";
import { onBlurCommonAction, saveChildForPropertyEdit } from "../../utils/PropertyEdit";

export const ContractTermForms = React.memo(function contractTermForms({
  property,
  initialized,
  contractterm,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [isOpenFormsModal, setIsOpenFormsModal] = React.useState(false)

  const [choices, setChoices] = React.useState({});
  const [propertyId, setPropertyId] = React.useState(null)

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  React.useEffect(() => {
    setChoices(props.choices);
  }, [props.choices]);

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  const {
    control,
    formState: { errors },
    setValue,
    methods,
    setError,
    clearErrors,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  useInitContractTerm(contractterm, setValue, isFocused)

  React.useEffect(() => {
    if (!contractterm) {
      return;
    }

    initialized();
  }, [contractterm]);

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const onBlurForContractTerm = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "contractterm",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const rules = React.useMemo(() => ({
    contractterm: {
      trade_form: {
        onBlur: onBlurForContractTerm,
      },
      surveying_condition: {
        onBlur: onBlurForContractTerm,
      },
      fixed_survey_map_up_date: {
        onBlur: onBlurForContractTerm,
      },
      narrow_map_up_date: {
        onBlur: onBlurForContractTerm,
      },
      restoration_drawing_up_date: {
        onBlur: onBlurForContractTerm,
      },
      border_transgression: {
        onBlur: onBlurForContractTerm,
      },
      contract_non_conformity_liability: {
        onBlur: onBlurForContractTerm,
      },
      contract: {
        onBlur: onBlurForContractTerm,
      },
      private_road_approval_form: {
        onBlur: onBlurForContractTerm,
      },
      plot_correction_registration: {
        onBlur: onBlurForContractTerm,
      },
      registration_of_subdivision: {
        onBlur: onBlurForContractTerm,
      },
      extradition_condition: {
        onBlur: onBlurForContractTerm,
      },
      dismantling_before_settlement: {
        onBlur: onBlurForContractTerm,
      },
      pre_payment_dismantling: {
        onBlur: onBlurForContractTerm,
      },
      pre_payment_development: {
        onBlur: onBlurForContractTerm,
      },
      pre_neighborhood_notice: {
        onBlur: onBlurForContractTerm,
      },
      pre_payment_announcement: {
        onBlur: onBlurForContractTerm,
      },
      buying_and_selling_by_others: {
        onBlur: onBlurForContractTerm,
      },
      land_category_change_registration: {
        onBlur: onBlurForContractTerm,
      },
      contract_type: {
        onBlur: onBlurForContractTerm,
      },
      plan_condition: {
        onBlur: onBlurForContractTerm,
      },
      effective_area_condition: {
        onBlur: onBlurForContractTerm,
      },
      effective_area: {
        onBlur: onBlurForContractTerm,
      },
      frontage_security_condition: {
        onBlur: onBlurForContractTerm,
      },
      frontage_security_area: {
        onBlur: onBlurForContractTerm,
      },
      development_position_designation_creation: {
        onBlur: onBlurForContractTerm,
      },
      abolition_notification: {
        onBlur: onBlurForContractTerm,
      },
      productive_green: {
        onBlur: onBlurForContractTerm,
      },
      violation_of_civil_law: {
        onBlur: onBlurForContractTerm,
      },
      building_confirmation: {
        onBlur: onBlurForContractTerm,
      },
      note1: {
        onBlur: onBlurForContractTerm,
      },
      note2: {
        onBlur: onBlurForContractTerm,
      },
    },
  }), [onBlurForContractTerm]);

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const title = "契約連絡表 - 契約条件"

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])
  return (
    <PropertyEditForms title={title}>
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={isOpenFormsModal}
          handleClose={() => {
            setIsOpenFormsModal(false)
            propertyEditFormDialogCloseAction()
          }}
          title={title}
          openedAction={loadingComplete}
          content={
            <Forms
              isModal={true}
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              disabled={props.disabled}
              setValue={setValue}
              property={property}
            />
          }
        />
        <form>
          <Paper
            elevation={formPaperElevation}
            variant={formPaperVariant}
            onClick={() => formAreaClickedAction(() => setIsOpenFormsModal(true))}
          >
            <Forms
              isModal={false}
              commonInputProps={commonInputProps}
              rules={rules}
              choices={choices}
              disabled={props.disabled}
              setValue={setValue}
              property={property}
            />
          </Paper>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});

ContractTermForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  contractterm: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

ContractTermForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const Forms = React.memo(function forms(props) {
  const {commonInputProps, rules, setValue, choices, property} = props;
  let leftSideFormListAll = [
    {
      name: "trade_form",
      label: "売買形態",
      choice: choices.trade_form,
    },
    {
      name: "surveying_condition",
      label: "測量条件",
      choice: choices.surveying_condition,
    },
    {
      name: "fixed_survey_map_up_date",
      label: "確定図UP期日",
      choice: choices.fixed_survey_map_up_date,
    },
    {
      name: "narrow_map_up_date",
      label: property.is_areacode_fukuoka ? "道路後退図UP期日":"狭隘図UP期日",
      choice: choices.narrow_map_up_date,
    },
    (
      !(property.is_areacode_fukuoka) ?
      {
        name: "restoration_drawing_up_date",
        label: "復元図UP期日",
        choice: choices.restoration_drawing_up_date,
      } :
      null
    ),
    {
      name: "border_transgression",
      label: "越境",
      choice: choices.border_transgression,
    },
    {
      name: "contract_non_conformity_liability",
      label: "契約不適合責任",
      choice: choices.contract_non_conformity_liability,
    },
    {
      name: "contract",
      label: "契約書",
      choice: choices.contract,
    },
    {
      name: "private_road_approval_form",
      label: "私道承諾書",
      choice: choices.private_road_approval_form,
    },
    {
      name: "plot_correction_registration",
      label: "地積更正登記",
      choice: choices.plot_correction_registration,
    },
    {
      name: "registration_of_subdivision",
      label: "分筆登記",
      choice: choices.registration_of_subdivision,
    },
    {
      name: "extradition_condition",
      label: "引渡条件",
      choice: choices.extradition_condition,
    },
    {
      name: "dismantling_before_settlement",
      label: "決済前解体",
      choice: choices.dismantling_before_settlement,
    },
    {
      name: "dismantling_before_settlement_for_fukuoka",
      label: "決済前解体",
      options: [{
        name: "pre_payment_dismantling",
        label: "決済前解体",
        choice: choices.pre_payment_dismantling,
      },
      {
        name: "pre_payment_development",
        label: "決済前造成",
        choice: choices.pre_payment_development,
      },
      {
        name: "pre_neighborhood_notice",
        label: "事前近隣挨拶",
        choice: choices.pre_neighborhood_notice,
      },
      {
        name: "pre_payment_announcement",
        label: "決済前発表",
        choice: choices.pre_payment_announcement,
      }],
    },
    {
      name: "buying_and_selling_by_others",
      label: "他人物売買",
      choice: choices.buying_and_selling_by_others,
    },
  ].filter(item => item !== null);

  console.log("===============")
  console.log(leftSideFormListAll)

  let label_25 = ""
  let label_26 = ""
  if (property.is_areacode_fukuoka){
    // 福岡
    leftSideFormListAll = leftSideFormListAll.filter((item) => item.name !== "dismantling_before_settlement");
    label_25 = "その他契約条件"
    label_26 = "引継ぎ事項"
  }else{
    // 福岡以外
    leftSideFormListAll = leftSideFormListAll.filter((item) => item.name !== "dismantling_before_settlement_for_fukuoka");
    label_25 = ""
    label_26 = ""
  }

  const leftSideFormList = React.useMemo(() => (leftSideFormListAll), [choices]);

  const rightSideFormList = React.useMemo(() => ([
    {
      name: "land_category_change_registration",
      label: "地目変更登記",
      choice: choices.land_category_change_registration,
    },
    {
      name: "contract_type",
      label: "契約形態",
      choice: choices.contract_type,
    },
    {
      name: "plan_condition",
      label: "プラン条件",
      choice: choices.plan_condition,
    },
    {
      name: "effective_area_condition",
      area_name: "effective_area",
      label: "有効面積条件",
      choice: choices.effective_area_condition,
    },
    {
      name: "frontage_security_condition",
      area_name: "frontage_security_area",
      label: "間口確保条件",
      choice: choices.frontage_security_condition,
    },
    {
      name: "development_position_designation_creation",
      label: "開発・位置指定・造成",
      choice: choices.development_position_designation_creation,
    },
    {
      name: "abolition_notification",
      label: "廃止届",
      choice: choices.abolition_notification,
    },
    {
      name: "productive_green",
      label: "生産緑地",
      choice: choices.productive_green,
    },
    (
      !(property.is_areacode_fukuoka) ?
      {
        name: "violation_of_civil_law",
        label: "民法破り",
        choice: choices.violation_of_civil_law,
      }:
      null
    ),
    {
      name: "building_confirmation",
      label: "建築確認",
      choice: choices.building_confirmation,
    },
    {
      name: "note1",
      label : label_25,
      choice: null,
    },
    {
      name: "note2",
      label : label_26,
      choice: null,
    },
  ]), [choices]);
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={props.isModal ? 12 : 6}>
        <Grid container spacing={1} alignItems="flex-end">
          {leftSideFormList.map((item, index) => {
            if (item.name === "dismantling_before_settlement_for_fukuoka") {
              return (
                <Box mt={1} ml={1} width={"100%"}>
                  <Box>
                    <InputLabel>{index + 1}. 決済前解体</InputLabel>
                  </Box>
                  <Box display={"flex"} gap={1}>
                    {item.options.map((option, index) => {
                      return (
                        <ContractTermForm
                          key={index}
                          {...option}
                          label_num={""}
                          commonInputProps={commonInputProps}
                          rules={rules}
                          disabled={props.disabled}
                          setValue={setValue}
                        />
                      );
                    })}
                  </Box>
                </Box>
              );
            }
            return (
              <ContractTermForm
                key={index}
                {...item}
                label_num={index + 1}
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled || (item.name === "restoration_drawing_up_date" && property.is_areacode_fukuoka)}
                setValue={setValue}
              />
            )
          })}
        </Grid>
      </Grid>

      <Grid item xs={props.isModal ? 12 : 6}>
        <Grid container spacing={1} alignItems="flex-end">
          {rightSideFormList.map((item, index) => {
            return (
              <ContractTermForm
                key={index}
                {...item}
                label_num={index + 1 + leftSideFormList.length}
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled}
                setValue={setValue}
              />
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
})
Forms.propTypes = {
  choices: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  rules: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};

const ContractTermForm = (props) => {
  const { name, area_name, label, label_num, choice, commonInputProps, rules, setValue } = props;
  if (choice === undefined) {
    return;
  }
  if (choice) {
    if (area_name) {
      return (
        <React.Fragment key={label_num}>
          <Grid item xs={8}>
            <SelectForm
              {...commonInputProps}
              label={`${label_num}. ${label ?? ""}`}
              name={`contractterm.${name}`}
              rules={rules.contractterm[name]}
              choices={choice}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...commonInputProps}
              name={`contractterm.${area_name}`}
              rules={rules.contractterm[area_name]}
              type="float"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">㎡</InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid item xs={12} key={label_num}>
          <SelectForm
            {...commonInputProps}
            label={`${label_num}. ${label ?? ""}`}
            name={`contractterm.${name}`}
            rules={rules.contractterm[name]}
            choices={choice}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>
      );
    }
  } else {
    return (
      <Grid item xs={12} key={label_num}>
        <TextField
          {...commonInputProps}
          label={`${label_num}. ${label ?? ""}`}
          name={`contractterm.${name}`}
          rules={rules.contractterm[name]}
          multiline
          rows={3}
          disabled={props.disabled}
        />
      </Grid>
    );
  }
};
ContractTermForm.propTypes = {
  name: PropTypes.string.isRequired,
  area_name: PropTypes.string,
  label: PropTypes.string,
  label_num: PropTypes.number.isRequired,
  choice: PropTypes.array,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};
ContractTermForm.defaultProps = {
  disabled: false,
};
